import { Contract } from '@ethersproject/contracts'
import { InterfaceEventName } from '@uniswap/analytics-events'
import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  ChainId,
  ENS_REGISTRAR_ADDRESSES,
  MULTICALL_ADDRESSES,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  TICK_LENS_ADDRESSES,
  V2_ROUTER_ADDRESSES,
  V3_MIGRATOR_ADDRESSES,
} from '@uniswap/sdk-core'
import IUniswapV2PairJson from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import IUniswapV2Router02Json from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import TickLensJson from '@uniswap/v3-periphery/artifacts/contracts/lens/TickLens.sol/TickLens.json'
import UniswapInterfaceMulticallJson from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import V3MigratorJson from '@uniswap/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json'
import { useWeb3React } from '@web3-react/core'
import ARGENT_WALLET_DETECTOR_ABI from 'abis/argent-wallet-detector.json'
import ASSET_MANAGER_ABI from 'abis/AssetManager.abi.json'
import BooLandABI from 'abis/BooLand.json'
import SpookV2ABI from 'abis/BooV2.json'
import EIP_2612 from 'abis/eip_2612.json'
import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import ERC721_ABI from 'abis/erc721.json'
import ERC1155_ABI from 'abis/erc1155.json'
import FantomAdapterABI from 'abis/fantomAdapter.json'
import fetchHelperABI from 'abis/FarmFetchHelper.json'
import IConverterLogABI from 'abis/IConverterLog.json'
import LIQUIDITY_BREWER_ABI from 'abis/LiqBrewer.json'
import masterChef from 'abis/masterchef.json'
import masterChefV2 from 'abis/MasterChefV2.json'
import masterChefV3 from 'abis/MasterChefV3.json' // TODO: Update to real abi
import MEMBERSHIP_MANAGER_ABI from 'abis/MembershipManager.abi.json'
import MOCK_NFT_ORDER_FILLER_ABI from 'abis/MockNFTOrderFiller.abi.json'
import MOCK_ORDER_FILLER_ABI from 'abis/MockOrderFiller.abi.json'
import SonicXbooABI from 'abis/SonicXboo.json'
import SpookABI from 'abis/Spook.json'
import SPOOKY_LAUNCHPAD_ABI from 'abis/SpookyLaunchpad_ABI.json'
import SpookyswapConfigABI from 'abis/spookyswapConfig.json'
import NFT_ACCESS_PASS_ABI from 'abis/ThreeMarketAccessPass.abi.json'
import TokenConverterABI from 'abis/tokenconverter.json'
import {
  ArgentWalletDetector,
  AssetManagerAbi,
  BooLand,
  BooV2,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc721,
  Erc1155,
  FantomAdapter,
  FarmFetchHelper,
  IConverterLog,
  MembershipManagerAbi,
  MockNFTOrderFillerAbi,
  MockOrderFillerAbi,
  SonicXboo,
  Spook,
  SpookyLaunchpadABI,
  SpookyswapConfig,
  ThreeMarketAccessPassAbi,
  Tokenconverter,
  Univ3Staker,
  Weth,
} from 'abis/types'
import UNISWAPV3POOL_ABI from 'abis/UniswapV3Pool.json'
import UNIV3STAKERABI from 'abis/univ3-staker.json'
import UNIV3STAKERABIOLD from 'abis/univ3-staker-old.json'
import WETH_ABI from 'abis/weth.json'
import { sendAnalyticsEvent } from 'analytics'
import {
  ASSET_MANAGER_ADDRESS_MAP,
  BOO_ADDRESS,
  BOO_CONVERTER_ADDRESS,
  FANTOM_ADAPTER_ADDRESS,
  FARM_FETCH_HELPER_MAP,
  getVersionedMasterchefAddressMap,
  LAUNCHPAD_ADDRESS,
  MOCK_NFT_ORDER_FILLER,
  MOCK_ORDER_FILLER,
  NFT_ACCESS_PASS,
  SPOOKYSWAP_CONFIG_MAP,
  V3_UNIV3STAKER_MAP,
  V4_MEMBERSHIPMANAGER_MAP,
  XBOO_ADDRESS,
} from 'constants/addresses'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { RPC_PROVIDERS } from 'constants/providers'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useEffect, useMemo } from 'react'
import { NonfungiblePositionManager, TickLens, UniswapInterfaceMulticall } from 'types/v3'
import { UniswapV3Pool } from 'types/v3/UniswapV3Pool'
import { V3Migrator } from 'types/v3/V3Migrator'
import { getContract } from 'utils'

const { abi: IUniswapV2PairABI } = IUniswapV2PairJson
const { abi: IUniswapV2Router02ABI } = IUniswapV2Router02Json
const { abi: TickLensABI } = TickLensJson
const { abi: MulticallABI } = UniswapInterfaceMulticallJson
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
const { abi: V2MigratorABI } = V3MigratorJson

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

function useMainnetContract<T extends Contract = Contract>(address: string | undefined, ABI: any): T | null {
  const { chainId } = useWeb3React()
  //TODO:CHange to Mainnet if ETH chain gets implemented

  const isMainnet = chainId === DEFAULT_CHAIN_ID
  const contract = useContract(isMainnet ? address : undefined, ABI, false)
  return useMemo(() => {
    if (isMainnet) return contract
    if (!address) return null
    const provider = RPC_PROVIDERS[DEFAULT_CHAIN_ID]
    try {
      return getContract(address, ABI, provider)
    } catch (error) {
      console.error('Failed to get mainnet contract', error)
      return null
    }
  }, [address, ABI, contract, isMainnet]) as T
}

export function useV2MigratorContract() {
  return useContract<V3Migrator>(V3_MIGRATOR_ADDRESSES, V2MigratorABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useWeb3React()
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useMockOrderFillerContract(): MockOrderFillerAbi | null {
  return useContract(MOCK_ORDER_FILLER, MOCK_ORDER_FILLER_ABI, true)
}

// eslint-disable-next-line import/no-unused-modules
export function useMockNFTOrderFillerContract(): MockNFTOrderFillerAbi | null {
  return useContract(MOCK_NFT_ORDER_FILLER, MOCK_NFT_ORDER_FILLER_ABI, true)
}

export function useMembershipContract(): MembershipManagerAbi | null {
  return useContract(V4_MEMBERSHIPMANAGER_MAP, MEMBERSHIP_MANAGER_ABI, true)
}

export function useNFTAccessPassContract(): ThreeMarketAccessPassAbi | null {
  return useContract(NFT_ACCESS_PASS, NFT_ACCESS_PASS_ABI, false)
}

export function useAssetManager(): AssetManagerAbi | null {
  return useContract(ASSET_MANAGER_ADDRESS_MAP, ASSET_MANAGER_ABI, true)
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

export function useArgentWalletDetectorContract() {
  return useContract<ArgentWalletDetector>(ARGENT_WALLET_DETECTOR_ADDRESS, ARGENT_WALLET_DETECTOR_ABI, false)
}

export function useENSRegistrarContract() {
  return useMainnetContract<EnsRegistrar>(ENS_REGISTRAR_ADDRESSES[ChainId.MAINNET], ENS_ABI)
}

export function useENSResolverContract(address: string | undefined) {
  return useMainnetContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useV2RouterContract(): Contract | null {
  return useContract(V2_ROUTER_ADDRESSES, IUniswapV2Router02ABI, true)
}

export function useV2LiquidityBrewerContract(): Contract | null {
  return useContract(V2_ROUTER_ADDRESSES, LIQUIDITY_BREWER_ABI, true)
}

export function useSpookySwapConfig(): SpookyswapConfig | null {
  return useContract(SPOOKYSWAP_CONFIG_MAP, SpookyswapConfigABI, true)
}

export function useFarmFetchHelper(): FarmFetchHelper | null {
  return useContract(FARM_FETCH_HELPER_MAP, fetchHelperABI, true)
}

export function useFarmV3Helper(): Univ3Staker | null {
  const { chainId } = useWeb3React()
  return useContract(
    chainId == 250
      ? '0x9c04f7a1506aBDDe1A3Bb8aB1a2Da520bDF79E68'
      : chainId == 199
      ? '0xc9205E4b99d644e13aC38543A19eEFbB3D11bb94'
      : '',
    UNIV3STAKERABIOLD,
    true
  )
}

export function useFarmV31Helper(): Univ3Staker | null {
  return useContract(V3_UNIV3STAKER_MAP, UNIV3STAKERABI, true)
}

export function usePoolV3Contract(poolAddress: string): UniswapV3Pool | null {
  return useContract(poolAddress, UNISWAPV3POOL_ABI, true)
}

export function useTokenConvertContract(): Tokenconverter | null {
  return useContract(BOO_CONVERTER_ADDRESS, TokenConverterABI, true)
}

export function useBooLand(): BooLand | null {
  return useContract(XBOO_ADDRESS, BooLandABI, true)
}

export function useSpook(): Spook | null {
  return useContract(BOO_ADDRESS, SpookABI, true)
}

export function useSpookV2(): BooV2 | null {
  return useContract(BOO_ADDRESS, SpookV2ABI, true)
}

export function useXbooContract(): SonicXboo | null {
  return useContract(XBOO_ADDRESS, SonicXbooABI, true)
}

export function useConvertLogContract(address: string): IConverterLog | null {
  return useContract(address, IConverterLogABI, true)
}

export function SpookyLaunchpad(): SpookyLaunchpadABI | null {
  return useContract(LAUNCHPAD_ADDRESS, SPOOKY_LAUNCHPAD_ABI, true)
}

export function useFantomAdapterContract(): FantomAdapter | null {
  return useContract(FANTOM_ADAPTER_ADDRESS, FantomAdapterABI, true)
}

const versionChefABI: any = {
  1: masterChef,
  2: masterChefV2,
  3: masterChefV3,
}
export function useVersionedMasterchef(version: number): Contract | null {
  const mastChefAddressMap = useMemo(() => getVersionedMasterchefAddressMap(version)!, [version])
  return useContract(mastChefAddressMap, versionChefABI[version], true)
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESSES, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useMainnetInterfaceMulticall() {
  return useMainnetContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESSES[ChainId.MAINNET],
    MulticallABI
  ) as UniswapInterfaceMulticall
}

export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  const { account } = useWeb3React()
  const contract = useContract<NonfungiblePositionManager>(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
    NFTPositionManagerABI,
    withSignerIfPossible
  )

  useEffect(() => {
    if (contract && account) {
      sendAnalyticsEvent(InterfaceEventName.WALLET_PROVIDER_USED, {
        source: 'useV3NFTPositionManagerContract',
        contract,
      })
    }
  }, [account, contract])
  return contract
}

export function useV3NFTPositionManagerContractOld(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  const { account } = useWeb3React()
  const contract = useContract<NonfungiblePositionManager>(
    '0xBAb4A13713C4dfba3073d0b35e2829F8be800310',
    NFTPositionManagerABI,
    withSignerIfPossible
  )

  useEffect(() => {
    if (contract && account) {
      sendAnalyticsEvent(InterfaceEventName.WALLET_PROVIDER_USED, {
        source: 'useV3NFTPositionManagerContractOld',
        contract,
      })
    }
  }, [account, contract])
  return contract
}

export function useTickLens(): TickLens | null {
  const { chainId } = useWeb3React()
  const address = chainId ? TICK_LENS_ADDRESSES[chainId] : undefined
  return useContract(address, TickLensABI) as TickLens | null
}
