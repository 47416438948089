import { Trans } from '@lingui/macro'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'

export default function TokenToggle({
  rangeOption,
  handleRangeToggle,
  text1,
  text2,
}: {
  rangeOption: boolean
  handleRangeToggle: () => void
  text1: string
  text2: string
}) {
  const isSorted = rangeOption

  return (
    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }} onClick={handleRangeToggle}>
      <ToggleWrapper width="fit-content">
        <ToggleElement isActive={isSorted} fontSize="16px">
          <Trans>{isSorted ? text1 : text1}</Trans>
        </ToggleElement>
        <ToggleElement isActive={!isSorted} fontSize="16px">
          <Trans>{isSorted ? text2 : text2}</Trans>
        </ToggleElement>
      </ToggleWrapper>
    </div>
  )
}
