import { BigNumber } from '@ethersproject/bignumber'
import { nativeOnChain } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import { AutoColumn } from 'components/Column'
import { ArrowChangeDown } from 'components/Icons/ArrowChangeDown'
import { ArrowChangeUp } from 'components/Icons/ArrowChangeUp'
import { RowBetween } from 'components/Row'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { SpookyLaunchpad } from 'hooks/useContract'
import { createChart, CrosshairMode, ISeriesApi } from 'lightweight-charts'
import { formatWeiToDecimal } from 'nft/utils'
import { formatMarketCap, formatVolume, ProgressBarInner, ProgressBarOuter, ProgressStack } from 'pages/LaunchPad'
import {
  LaunchpadToken,
  useGetLatestTradesWithAmount,
  useGetTrades,
  useWNativeUSDPrice,
} from 'pages/LaunchPad/hooks/useLaunchpadTokens'
import React, { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { getExplorerLink } from 'utils/getExplorerLink'
import { ExplorerDataType } from 'utils/getExplorerLink'

import { DELTA_MCAP_REQUIRED, STARTING_MCAP_ETHER, WHALE_ICONS_USD_AMOUNTS } from '../../pages/LaunchPad/const'
import { ChartWrapper, Row, SocialLinks, TokenInfoSection, TokenMetrics } from './styled'

const TokenStats = styled.div`
  display: flex;
  flex-direction: column;
`

const TradeHistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    padding-left: 0px;
    font-size: 16px;
  }

  td {
    color: ${({ theme }) => theme.neutral2};
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  td:nth-child(2) {
    text-align: left;
  }

  th,
  td {
    text-align: right;
  }

  th:last-child,
  td:last-child {
    padding-right: 0px;
    text-align: right;
    width: 150px; /* Adjust this value as needed */
  }
`

const NoTradesMessage = styled.div`
  margin-top: 10px;
  text-align: center;
  color: ${({ theme }) => theme.white};
`

const TradeType = styled.span<{ isBuy: boolean }>`
  color: ${({ isBuy }) => (isBuy ? '#26a69a' : '#ef5350')};
  min-width: 50px;
  width: 100%;

  text-align: center;
  align-items: center;
`

interface PriceChartProps {
  tokenAddress: string
  tokenName?: string
  tokenSymbol?: string
  tokenPrice: string
  token?: LaunchpadToken
  volume24h?: number
  volume24hAgo?: number
  socialLinks?: {
    twitter?: string
    telegram?: string
    website?: string
  }
  showUSDPrice: boolean
  nativeTokenSymbol: string
}

const renderArrow = (buyer: boolean) => {
  if (buyer) return <ArrowChangeUp height={20} color="#26a69a" />
  else return <ArrowChangeDown height={20} color="#ef5350" />
}

export const formatWeiSymbol = (amount: string, symbol: string) => {
  const formatted: string = formatWeiToDecimal(amount)
  if (symbol != '' && formatted == '< 0.00001') {
    symbol = 'n'.concat(symbol)
  }
  if (formatted.includes('e')) {
    return symbol
  }

  const split = formatted.split('.')

  if (symbol != '' && formatted.length > 18) {
    symbol = 'G'.concat(symbol)
  }
  return symbol
}

export const formatAmountWithIcon = (
  trade: any,
  WNATIVE_USD_PRICE: number,
  showUSDPrice: boolean,
  nativeTokenSymbol: string,
  useEthIn: boolean
) => {
  const usdAmount = formatWeiToDecimal(
    BigNumber.from(useEthIn ? trade.ethIn : trade.ethOut)
      .mul((1000000000 * (WNATIVE_USD_PRICE ? WNATIVE_USD_PRICE : 1)).toFixed(0))
      .div(1000000000)
      .toHexString()
  )
  return (
    <>
      {usdAmount < WHALE_ICONS_USD_AMOUNTS[0] ? (
        <img src="/images/Fish.png" />
      ) : usdAmount < WHALE_ICONS_USD_AMOUNTS[1] ? (
        <img src="/images/Dolphin.png" />
      ) : (
        <img src="/images/Whale.png" />
      )}
      <span style={{ color: 'white', textAlign: 'right', flex: 3, fontWeight: '400' }}>
        {showUSDPrice
          ? '$' + usdAmount
          : formatWeiToDecimal(BigNumber.from(useEthIn ? trade.ethIn : trade.ethOut).toHexString()) +
            ' ' +
            nativeTokenSymbol}
      </span>
    </>
  )
}

export function formatTimeAgo(timestamp: number) {
  const tradeDate = new Date(timestamp * 1000)
  const now = new Date()

  const diff = now.getTime() - tradeDate.getTime()

  const MS_IN_SECONDS = 1000
  const MS_IN_MINUTE = 60 * 1000
  const MS_IN_HOUR = 60 * MS_IN_MINUTE
  const MS_IN_DAY = 24 * MS_IN_HOUR
  const MS_IN_YEAR = 365 * MS_IN_DAY

  const years = Math.floor(diff / MS_IN_YEAR)
  const days = Math.floor((diff % MS_IN_YEAR) / MS_IN_DAY)
  const hours = Math.floor((diff % MS_IN_DAY) / MS_IN_HOUR)
  const minutes = Math.floor((diff % MS_IN_HOUR) / MS_IN_MINUTE)
  const seconds = Math.floor((diff % MS_IN_MINUTE) / MS_IN_SECONDS)

  const formatUnit = (value: number, unit: string) => `${value} ${unit}${value > 1 && unit != 's' ? 's' : ''}`

  const parts = []
  if (years) parts.push(formatUnit(years, 'yr'))
  if (days) parts.push(formatUnit(days, 'day'))
  if (hours) parts.push(formatUnit(hours, 'hr'))
  if (minutes) parts.push(formatUnit(minutes, 'min'))
  if (seconds) parts.push(formatUnit(seconds, 's'))

  const formatted = parts.slice(0, 2).join(' ') + ' ago'

  return formatted || 'Just now'
}

const PriceChart: React.FC<PriceChartProps> = ({
  tokenAddress,
  tokenName,
  tokenSymbol,
  tokenPrice,
  token,
  volume24h,
  volume24hAgo,
  showUSDPrice,
  nativeTokenSymbol,
  socialLinks = {},
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const nativeTokenName = nativeOnChain(chainIdOrDefault).symbol
  const native = chainId ? nativeOnChain(chainId) : undefined

  const chartRef = useRef<ReturnType<typeof createChart>>()
  const seriesRef = useRef<ISeriesApi<'Candlestick'>>()

  const { trades, refetch: refetchTrades } = useGetTrades(tokenAddress)
  const { tradesWithAmount, refetch: refetchTradesWithAmount } = useGetLatestTradesWithAmount(tokenAddress, 25)
  const LaunchpadContract = SpookyLaunchpad()

  const breakpointMd = 768
  const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(window.innerWidth >= breakpointMd)

  const { wnativePrice: WNATIVE_USD_PRICE } = useWNativeUSDPrice()
  const marketCap = formatMarketCap(tokenPrice, WNATIVE_USD_PRICE, showUSDPrice, nativeTokenName)

  useEffect(() => {
    const handleResize = () => {
      setIsAboveBreakpoint(window.innerWidth >= breakpointMd)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (isAboveBreakpoint && chartContainerRef.current) {
      // Apply background image directly to the chart container
      chartContainerRef.current.style.backgroundImage = `url("https://assets.spooky.fi/spooky-brand-bg.png")`
      chartContainerRef.current.style.backgroundRepeat = 'no-repeat'
      chartContainerRef.current.style.backgroundPosition = 'center'
      chartContainerRef.current.style.backgroundSize = '200px' // Adjust the size as needed

      chartRef.current = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        layout: {
          background: { type: 'solid', color: '#182035dd' },
          textColor: '#999',
          attributionLogo: false,
        },
        grid: {
          vertLines: {
            color: 'transparent',
          },
          horzLines: {
            color: 'transparent',
          },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        rightPriceScale: {
          //borderColor: '#ccc',
        },
        leftPriceScale: {
          visible: true,
        },
        timeScale: {
          borderColor: '#ccc',
        },
        handleScale: {
          axisPressedMouseMove: {
            price: false,
          },
        },
      })

      const hideNegativesUSDFormatter = (num) => {
        if (num < 0) return ''
        return Intl.NumberFormat(window.navigator.languages[0], {
          style: 'currency',
          currency: 'USD', // Currency for data points
        }).format(num)
      }

      const nativeFormatter = (num) => {
        if (num < 0) return ''
        return (
          num
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          ' ' +
          nativeTokenName
        )
      }

      const leftSeries = chartRef.current.addCandlestickSeries({
        priceScaleId: 'left',
        //upColor: '#2962FF',
        //downColor: '#2962FF',
        priceFormat: {
          type: 'custom',
          formatter: hideNegativesUSDFormatter,
        },
      })

      seriesRef.current = chartRef.current.addCandlestickSeries({
        priceFormat: {
          type: 'custom',
          formatter: nativeFormatter,
        },
      })

      const volumeSeries = chartRef.current.addHistogramSeries({
        priceFormat: {
          type: 'volume',
        },
        color: '#26a69a',
        priceScaleId: '', // set as an overlay by setting a blank priceScaleId
        priceLineVisible: false,
        lastValueVisible: false,
      })

      seriesRef.current.priceScale().applyOptions({
        scaleMargins: {
          top: 0.1, // highest point of the series will be 10% away from the top
          bottom: 0.2, // lowest point will be 80% away from the bottom
        },
      })

      leftSeries.priceScale().applyOptions({
        scaleMargins: {
          top: 0.1, // highest point of the series will be 10% away from the top
          bottom: 0.2, // lowest point will be 80% away from the bottom
        },
      })

      volumeSeries.priceScale().applyOptions({
        // set the positioning of the volume series
        scaleMargins: {
          top: 0.9, // highest point of the series will be 90% away from the top
          bottom: 0,
        },
        visible: false,
      })

      //starting price
      const SCALING_FACTOR = 1e9
      const STARTING_PRICE: number = 7180000000000 / SCALING_FACTOR

      const parseDataIntoCandles = (candleSeconds: number) => {
        //TODO: improve
        const candleData = []
        const volumeData = []
        let volumeSum = 0
        let volumeAbs = 0
        let priceOpen: number = STARTING_PRICE
        let priceCurrent: number = STARTING_PRICE
        let priceHigh: number = STARTING_PRICE
        let priceLow: number = STARTING_PRICE
        let candleStartTime: number = +trades[0].blockTimestamp
        const firstCandle = true
        for (const trade of trades) {
          //console.log(trade)
          if (+trade.blockTimestamp > candleStartTime + candleSeconds) {
            //create last candle
            if (
              priceOpen == priceHigh &&
              priceLow == priceHigh &&
              priceCurrent == priceLow &&
              priceCurrent == STARTING_PRICE
            ) {
            } else
              candleData.push({
                open: priceOpen,
                high: priceHigh,
                low: priceLow,
                close: priceCurrent,
                time: candleStartTime * 1,
              })
            volumeData.push({
              time: candleStartTime * 1,
              color: volumeSum < 0 ? '#ef535088' : '#26a69a88',
              value: volumeAbs,
            })
            //refresh current candle
            priceOpen = priceCurrent
            priceHigh = priceCurrent
            priceLow = priceCurrent
            candleStartTime = +trade.blockTimestamp
            volumeSum = 0
            volumeAbs = 0
          }

          //update candle
          priceCurrent = trade.newPrice as unknown as number
          priceCurrent /= SCALING_FACTOR
          if (priceCurrent > priceHigh) priceHigh = priceCurrent
          if (priceCurrent < priceLow) priceLow = priceCurrent
          if (trade.ethIn != undefined) {
            volumeSum += +trade.ethIn / SCALING_FACTOR
            volumeAbs += +trade.ethIn / SCALING_FACTOR
          }
          if (trade.ethOut != undefined) {
            volumeSum -= +trade.ethOut / SCALING_FACTOR
            volumeAbs += +trade.ethOut / SCALING_FACTOR
          }
        }
        candleData.push({
          open: priceOpen,
          high: priceHigh,
          low: priceLow,
          close: priceCurrent,
          time: candleStartTime * 1,
        })
        volumeData.push({
          time: candleStartTime * 1,
          color: volumeSum < 0 ? '#ef5350' : '#26a69a',
          value: volumeAbs,
        })

        //console.log(candleData)
        return { candleData, volumeData }
      }

      const _1S = 1
      const _30S = 30
      const _1M = 60
      const _5M = 60 * 5
      const _30M = 60 * 30
      const _1H = 60 * 60
      const _4H = 60 * 60 * 4
      const _12H = 60 * 60 * 12
      const _1D = 60 * 60 * 24

      if (trades.length > 0) {
        const { candleData, volumeData } = parseDataIntoCandles(_1S)
        seriesRef.current?.setData(candleData)
        const modifiedParsed = candleData.map((candle) => {
          return {
            ...candle,
            open: candle.open * WNATIVE_USD_PRICE,
            high: candle.high * WNATIVE_USD_PRICE,
            low: candle.low * WNATIVE_USD_PRICE,
            close: candle.close * WNATIVE_USD_PRICE,
          }
        })
        leftSeries.setData(modifiedParsed)
        volumeSeries.setData(volumeData)
      }
      chartRef.current?.timeScale().fitContent()
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.remove()
        chartRef.current = undefined
      }
    }
  }, [isAboveBreakpoint, tokenAddress, trades])

  useEffect(() => {
    const interval = setInterval(() => {
      refetchTrades()
      refetchTradesWithAmount()
    }, 2000) // Poll every 2 seconds

    return () => clearInterval(interval)
  }, [refetchTrades, refetchTradesWithAmount])

  useEffect(() => {
    if (!LaunchpadContract || !tokenAddress) return

    const handleSwap = async (
      sender: string,
      amount0In: any,
      amount1In: any,
      amount0Out: any,
      amount1Out: any,
      to: string
    ) => {
      console.log('New swap detected in chart, refreshing data...')
      refetchTrades()
      refetchTradesWithAmount()
    }

    LaunchpadContract.on('Swap', handleSwap)

    return () => {
      LaunchpadContract.off('Swap', handleSwap)
    }
  }, [LaunchpadContract, tokenAddress, refetchTrades, refetchTradesWithAmount])

  const theme = useTheme()

  // const [calculatedValue, setCalculatedValue] = useState<bigint>(BigInt(0))

  // useEffect(() => {
  //   // Calculate value only when marketCap is available and valid
  //   if (!isNaN(WNATIVE_USD_PRICE) && !isNaN(marketCap) && marketCap !== 0) {
  //     const calculatedValue = WNATIVE_USD_PRICE * marketCap
  //     const roundedValue = Math.round(calculatedValue)
  //     setCalculatedValue(utils.parseUnits(roundedValue.toString(), 'wei'))
  //   } else {
  //     setCalculatedValue(BigInt(0))
  //   }
  // }, [marketCap])

  // const value = calculatedValue
  return (
    <>
      <TokenInfoSection>
        <Row>
          <div style={{ flex: 1 }}>
            <TokenMetrics>
              <TokenStats>
                <div
                  style={{
                    fontWeight: '200',
                    whiteSpace: 'nowrap',
                    color: theme.neutral2,
                    fontSize: 12,
                    display: 'inline',
                    textAlign: 'left',
                  }}
                >
                  Market Cap
                </div>
                <div
                  style={{ fontWeight: '300', whiteSpace: 'nowrap', color: 'white', fontSize: 36, display: 'inline' }}
                >
                  {marketCap && <>{marketCap}</>}
                </div>
              </TokenStats>
            </TokenMetrics>
            <ProgressStack>
              <div>
                <ProgressBarOuter type="success">
                  <ProgressBarInner
                    progress={
                      /*       100 * (mcap - startMcap) / deltaMcapRequired    */
                      (100 * ((Number(tokenPrice) * 1000000000) / 1e18 - (STARTING_MCAP_ETHER - 0.001))) /
                      DELTA_MCAP_REQUIRED
                    }
                    type="success"
                  />
                </ProgressBarOuter>
              </div>
            </ProgressStack>
          </div>
          <div style={{ flex: 1 }}>
            <TokenMetrics>
              <TokenStats>
                <div
                  style={{
                    fontWeight: '200',
                    whiteSpace: 'nowrap',
                    color: theme.neutral2,
                    fontSize: 12,
                    display: 'inline',
                    textAlign: 'left',
                  }}
                >
                  Volume (Today)
                </div>
                <div
                  style={{ fontWeight: '300', whiteSpace: 'nowrap', color: 'white', fontSize: 36, display: 'inline' }}
                >
                  {formatVolume(volume24h, WNATIVE_USD_PRICE, showUSDPrice, nativeTokenSymbol)}
                </div>
              </TokenStats>
            </TokenMetrics>
          </div>
          <div style={{ flex: 1 }}>
            <TokenMetrics>
              <TokenStats>
                <div
                  style={{
                    fontWeight: '200',
                    whiteSpace: 'nowrap',
                    color: theme.neutral2,
                    fontSize: 12,
                    display: 'inline',
                    textAlign: 'left',
                  }}
                >
                  Volume (Yesterday)
                </div>
                <div
                  style={{ fontWeight: '300', whiteSpace: 'nowrap', color: 'white', fontSize: 36, display: 'inline' }}
                >
                  {formatVolume(volume24hAgo, WNATIVE_USD_PRICE, showUSDPrice, nativeTokenSymbol)}
                </div>
              </TokenStats>
            </TokenMetrics>
          </div>
        </Row>
      </TokenInfoSection>
      <TokenInfoSection>
        {/* <TokenInfoHeader> */}
        {/* <TokenMetrics>
            <div>
              <label>Price</label>
              <span>{tokenPrice}</span>
              <CurrencyLogo currency={eth}></CurrencyLogo>
            </div>
            <div>
              <label>Market Cap</label>
              <span>{marketCap}</span>
            </div>
            <div>
              <label>24h Volume</label>
              <span>{volume24h}</span>
            </div>
          </TokenMetrics> */}

        <SocialLinks>
          {socialLinks.twitter && (
            <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          )}
          {socialLinks.telegram && (
            <a href={socialLinks.telegram} target="_blank" rel="noopener noreferrer">
              Telegram
            </a>
          )}
          {socialLinks.website && (
            <a href={socialLinks.website} target="_blank" rel="noopener noreferrer">
              Website
            </a>
          )}
        </SocialLinks>

        {/* <BuySellPanel token={null} account={account} /> */}
        {/* </TokenInfoHeader> */}
        <ChartWrapper ref={chartContainerRef} />
      </TokenInfoSection>
      <TokenInfoSection>
        <h2>Trade History</h2>
        {trades.length > 0 ? (
          <div
            style={{
              maxHeight: '400px',
              overflowY: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <style>
              {`
              /* Hide scrollbar for Webkit browsers */
              div::-webkit-scrollbar {
                display: none;
              }
            `}
            </style>
            <TradeHistoryTable>
              <thead>
                <tr>
                  <th>Market Cap</th>
                  <th>Amount</th>
                  <th>User</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {tradesWithAmount.map((trade, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        minWidth: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: trade.buyer ? '#26a69a' : '#ef5350',
                      }}
                    >
                      <RowBetween style={{ alignItems: 'left', justifyContent: 'left' }}>
                        {renderArrow(trade.buyer)}
                        {showUSDPrice && '$'}
                        {formatWeiToDecimal(
                          BigNumber.from(trade.newPrice)
                            .mul((1000000000 * (showUSDPrice ? WNATIVE_USD_PRICE : 1)).toFixed(0))
                            .toHexString()
                        )}
                        {!showUSDPrice && ' ' + nativeTokenSymbol}
                      </RowBetween>
                    </td>
                    {trade.ethIn && trade.tokensOut && native ? (
                      <td>
                        <AutoColumn>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'right',
                                width: '100%',
                              }}
                            >
                              {formatAmountWithIcon(trade, WNATIVE_USD_PRICE, showUSDPrice, nativeTokenSymbol, true)}
                              {/* <span style={{ textAlign: 'left', paddingLeft: '8px', flex: 1 }}>{symbol}</span> */}
                            </div>
                          </div>
                        </AutoColumn>
                      </td>
                    ) : trade.ethOut && trade.tokensIn ? (
                      <td>
                        <AutoColumn>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'right',
                                width: '100%',
                              }}
                            >
                              {formatAmountWithIcon(trade, WNATIVE_USD_PRICE, showUSDPrice, nativeTokenSymbol, false)}
                              {/* <span style={{ textAlign: 'left', paddingLeft: '8px', flex: 1 }}>{symbol}</span> */}
                            </div>
                          </div>
                        </AutoColumn>
                      </td>
                    ) : (
                      <></>
                    )}
                    <td>
                      {chainId && (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={getExplorerLink(
                            chainId,
                            trade.buyer ? trade.buyer : trade.seller,
                            ExplorerDataType.ADDRESS
                          )}
                        >
                          <span style={{ color: 'white' }}>{`${(trade.buyer ? trade.buyer : trade.seller).substring(
                            0,
                            6
                          )}...${(trade.buyer ? trade.buyer : trade.seller).slice(-4)}`}</span>
                        </a>
                      )}
                    </td>

                    <td>
                      <div>{formatTimeAgo(+trade.blockTimestamp)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TradeHistoryTable>
          </div>
        ) : (
          <NoTradesMessage>No trades available</NoTradesMessage>
        )}
      </TokenInfoSection>
    </>
  )
}

export default PriceChart
