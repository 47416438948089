import { t } from '@lingui/macro'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { SettingsToggle } from './SettingsToggle'

export const assetManagerAtom = atomWithStorage<boolean>('assetManager', false)

export function AssetManagerToggle() {
  const [isAssetManager, setIsAssetManager] = useAtom(assetManagerAtom)

  return (
    <SettingsToggle
      title={t`Use V4 AssetManager`}
      dataid="asset-manager-toggle"
      isActive={isAssetManager}
      toggle={() => void setIsAssetManager((value) => !value)}
    />
  )
}
