import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { ButtonConfirmed } from 'components/Button'
import { useAssetManager } from 'hooks/useContract'
import { useLogDependencyChanges } from 'hooks/useLogDependencyChanges'
import useTransactionDeadline, { useDebouncedTransactionDeadline } from 'hooks/useTransactionDeadline'
import {
  AccountIdentityKey,
  AssetManagerOrder,
  OrderSignatureData,
  useAccountIdentity,
  useMockAssetManagerOrderPermit,
} from 'hooks/v4/useAssetManagerOrderPermit'
import { useCallback } from 'react'
import { TradeFillType } from 'state/routing/types'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

interface MockOrderProps {
  order?: AssetManagerOrder
  signLabel: string
  createOrderLabel: string
}

// Custom Hook for handling asset manager orders
// eslint-disable-next-line import/no-unused-modules
export const useAssetManagerOrderCallback = (order: AssetManagerOrder | undefined) => {
  const transactionDeadline = useTransactionDeadline()
  const debouncedTransactionDeadline = useDebouncedTransactionDeadline(transactionDeadline)

  const accountIdentity: AccountIdentityKey | undefined = useAccountIdentity()
  const { signatureData, gatherPermitSignature } = useMockAssetManagerOrderPermit(
    order,
    accountIdentity,
    debouncedTransactionDeadline
  )

  useLogDependencyChanges(
    [order, signatureData, gatherPermitSignature],
    ['order', 'signatureData', 'gatherPermitSignature']
  )

  const addTransaction = useTransactionAdder()
  const assetManager = useAssetManager()

  const resolveOrderType = useCallback((orderType: number) => {
    switch (orderType) {
      case 0:
        return 'Swap'
      case 1:
        return 'Create V2 LP'
      case 2:
        return 'Create V3 LP'
      case 3:
        return 'Remove V2 LP'
      case 4:
        return 'Remove V3 LP'
      default:
        return 'Unknown'
    }
  }, [])

  const signOrderAndSend = useCallback(async () => {
    let signedSignatureData: OrderSignatureData | null = null
    if (gatherPermitSignature) {
      try {
        signedSignatureData = await gatherPermitSignature()
      } catch (error) {
        if (error?.code !== 4001) {
          // Handle other errors
        }
      }
    }

    if (!assetManager || !signedSignatureData) {
      return
    }

    // This isn't working currently but ive left the call to permit so the flow can be easier in the future
    // try {
    //   await assetManager.createOrderBySig(
    //     signedSignatureData.order,
    //     signedSignatureData.deadline,
    //     signedSignatureData.v,
    //     signedSignatureData.r,
    //     signedSignatureData.s
    //   )
    // } catch (error) {
    //   if (error?.code !== 4001) {

    //   }
    //   console.error(error)
    // }

    return assetManager.estimateGas.createOrder(signedSignatureData.order).then((estimatedGasLimit) => {
      return assetManager
        .createOrder(signedSignatureData.order, { gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          // if (tx.data !== response.data) {
          //   if (!response.data || response.data.length === 0 || response.data === '0x') {
          //     throw new ModifiedSwapError()
          //   }
          // }
          addTransaction(response, {
            type: TransactionType.V4_ORDER,
            orderType: resolveOrderType(BigNumber.from(signedSignatureData.order.orderType).toNumber()),
          })

          return {
            type: TradeFillType.Classic as const,
            response,
          }
        })
    })
  }, [addTransaction, assetManager, gatherPermitSignature, resolveOrderType])

  return {
    signOrderAndSend,
    signatureData,
    gatherPermitSignature,
  }
}

// Component
export const MockOrder: React.FC<MockOrderProps> = ({ order, signLabel, createOrderLabel }: MockOrderProps) => {
  const { signOrderAndSend, signatureData, gatherPermitSignature } = useAssetManagerOrderCallback(order)

  const signOrderCallback = useCallback(async () => {
    if (gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        if (error?.code !== 4001) {
          // Handle other errors
        }
      }
    }
  }, [gatherPermitSignature])

  return (
    <ButtonConfirmed confirmed={true} onClick={signOrderAndSend}>
      {signLabel}
    </ButtonConfirmed>
  )
}
