import { getAddress } from '@ethersproject/address'
import { BigNumber, BigNumberish } from '@ethersproject/bignumber'
import { BytesLike, hexlify, splitSignature } from '@ethersproject/bytes'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { ADDRESS_ZERO } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { ASSET_MANAGER_ADDRESS_MAP } from 'constants/addresses'
import { BYTES_EMPTY } from 'constants/misc'
import { useAtom } from 'jotai'
import JSBI from 'jsbi'
import { accountIdentityOrganizationIdAtom, accountIdentityRelatedIdAtom } from 'pages/MintNFT/Components/nftSelector'
import { getAssetManagerId } from 'pages/MintNFT/Utils/assetManagerUtils'
import { useMemo, useState } from 'react'

// 20 minutes to submit after signing
const PERMIT_VALIDITY_BUFFER = 20 * 60

enum UseOrderPermitState {
  // returned for any reason, e.g. it is an argent wallet, or the currency does not support it
  NOT_APPLICABLE,
  LOADING,
  NOT_SIGNED,
  SIGNED,
}

export interface OrderSignatureData {
  v: number
  r: string
  s: string
  deadline: number
  // nonce: number
  chainId: number
  order: TransactionOrder
}

type OrderAsset =
  | CurrencyAmount<Currency>
  | CurrencyAmount<Token>
  | { address: string; id: BigNumber; isAssetUnknown: boolean }

// eslint-disable-next-line import/no-unused-modules
export function isCurrencyAmount(asset: OrderAsset): asset is CurrencyAmount<Currency> {
  return 'currency' in asset
}

// eslint-disable-next-line import/no-unused-modules
export interface AssetManagerOrder {
  orderType: BigNumber
  assetForSale: OrderAsset
  isAssetToReceiveUnknown: boolean
  assetToReceive: OrderAsset
  params: string
  expirationTimeStamp: BigNumber
}

export interface V3LiquidityOrderParams {
  amount0Desired: BigNumber
  amount1Desired: BigNumber
  amount0Min: BigNumber
  amount1Min: BigNumber
  tickLower: BigNumber
  tickUpper: BigNumber
  nftPositionManager: string
  poolAddress: string
}

export interface V2LiquidityOrderParams {
  tokenA: string
  tokenB: string
  amountADesired: BigNumber
  amountBDesired: BigNumber
  amountAMin: BigNumber
  amountBMin: BigNumber
}

export interface V3RemoveLiquidityOrderParams {
  amount0Min: BigNumber
  amount1Min: BigNumber
  nftPositionManager: string
  liquidityAmount: BigNumber
}

export interface AccountIdentityKey {
  organizationId: BigNumber
  relatedId: BigNumber
}

// eslint-disable-next-line import/no-unused-modules
export interface TransactionAccountIdentityKey {
  organizationId: BigNumberish
  relatedId: BigNumberish
}

// eslint-disable-next-line import/no-unused-modules
export interface TransactionAssetKey {
  assetAddress: string
  id: BigNumberish
  assetType: BigNumberish
}

// eslint-disable-next-line import/no-unused-modules
export interface TransactionOrder {
  id: BigNumberish
  orderType: BigNumberish
  accountId: BytesLike
  organizationId: BigNumberish
  relatedId: BigNumberish
  assetForSale: TransactionAssetKey
  assetForSaleAmount: BigNumberish
  isAssetToReceiveUnknown: boolean
  assetToReceive: TransactionAssetKey
  assetToReceiveAmount: BigNumberish
  expirationTimeStamp: BigNumberish
  feeDetails: {
    assetKey: TransactionAssetKey
    amount: BigNumberish
    feeAccountId: BytesLike
  }
  params: BytesLike
}

// eslint-disable-next-line import/no-unused-modules
export function getSwapCurrencyAddressForOrder(orderAsset: OrderAsset): string {
  if (!isCurrencyAmount(orderAsset)) {
    return orderAsset.address
  }

  return getCurrencyAddressForOrder(orderAsset.currency)
}

export function getCurrencyAddressForOrder(currency: Currency) {
  if (currency.isToken) {
    return getAddress(currency.address)
  }
  return ADDRESS_ZERO
}

// eslint-disable-next-line import/no-unused-modules
export function getOrderAssetAmount(orderAsset: OrderAsset) {
  if (!isCurrencyAmount(orderAsset)) {
    return JSBI.BigInt(1)
  }
  return orderAsset.numerator
}

export enum AssetType {
  Native, //0
  Token, //1
  NFT, //2
  // MultiNFT //3
}

function getAssetType(orderAsset: OrderAsset): string {
  if (!isCurrencyAmount(orderAsset)) {
    return '2'
  }
  if (orderAsset.currency.isNative) {
    return '0'
  }

  return '1'
}

function getAssetTypeFromCurrency(currency: Currency): AssetType {
  if (currency.isNative) {
    return AssetType.Native
  }

  return AssetType.Token
}

export function useAccountIdentity(): AccountIdentityKey | undefined {
  //this is a mock return real value in the future
  const [accountIdentityRelatedId] = useAtom(accountIdentityRelatedIdAtom)
  const [accountIdentityOrganizationId] = useAtom(accountIdentityOrganizationIdAtom)

  const accountIdentity: AccountIdentityKey | undefined = useMemo(() => {
    if (!accountIdentityRelatedId || !accountIdentityOrganizationId) return
    return {
      organizationId: BigNumber.isBigNumber(accountIdentityOrganizationId)
        ? accountIdentityOrganizationId
        : BigNumber.from(accountIdentityOrganizationId),

      relatedId: BigNumber.isBigNumber(accountIdentityRelatedId)
        ? accountIdentityRelatedId
        : BigNumber.from(accountIdentityRelatedId),
    }
  }, [accountIdentityRelatedId, accountIdentityOrganizationId])

  return accountIdentity
}

export function useAccountIdentityWithHash():
  | { accountIdentity: AccountIdentityKey; accountHash?: string }
  | undefined {
  const accountIdentity = useAccountIdentity()
  if (!accountIdentity) return

  const accountHash = getAssetManagerId(accountIdentity.organizationId, accountIdentity.relatedId)

  return {
    accountIdentity,
    accountHash,
  }
}

function isAssetObject(asset: OrderAsset): asset is { address: string; id: BigNumber; isAssetUnknown: boolean } {
  return (
    typeof asset === 'object' &&
    asset !== null &&
    'isAssetUnknown' in asset &&
    typeof asset.isAssetUnknown === 'boolean'
  )
}

export function getAssetKey(orderAsset: OrderAsset): TransactionAssetKey {
  return {
    assetAddress: getSwapCurrencyAddressForOrder(orderAsset),
    id: isAssetObject(orderAsset) ? orderAsset.id.toString() : '0', /// assetId for Tokens and Native will always be 0
    assetType: getAssetType(orderAsset),
  }
}

// eslint-disable-next-line import/no-unused-modules
export function getAssetKeyFromCurrency(currency: Currency | null | undefined): TransactionAssetKey | undefined {
  if (!currency) return
  return {
    assetAddress: getCurrencyAddressForOrder(currency),
    id: '0', /// assetId for Tokens and Native will always be 0
    assetType: getAssetTypeFromCurrency(currency),
  }
}

function getAssetToReceiveIsUnknown(orderAsset: OrderAsset) {
  if (!isCurrencyAmount(orderAsset)) {
    return orderAsset.isAssetUnknown
  }

  return false
}

// eslint-disable-next-line import/no-unused-modules
export function useMockAssetManagerOrderPermit(
  order: AssetManagerOrder | undefined,
  accountIdentityKey: AccountIdentityKey | undefined,
  transactionDeadline: BigNumber | undefined
): {
  signatureData: OrderSignatureData | null
  state: UseOrderPermitState
  gatherPermitSignature: null | (() => Promise<OrderSignatureData | null>)
} {
  const { account, chainId, provider } = useWeb3React()
  const [signatureData, setSignatureData] = useState<OrderSignatureData | null>(null)

  return useMemo(() => {
    if (
      !order ||
      !accountIdentityKey ||
      !account ||
      !chainId ||
      !ASSET_MANAGER_ADDRESS_MAP[chainId] ||
      !transactionDeadline ||
      !provider
    ) {
      return {
        state: UseOrderPermitState.NOT_APPLICABLE,
        signatureData: null,
        gatherPermitSignature: null,
      }
    }

    const isSignatureDataValid =
      signatureData &&
      signatureData.deadline >= transactionDeadline.toNumber() &&
      signatureData.v &&
      signatureData.r &&
      signatureData.s

    return {
      state: isSignatureDataValid ? UseOrderPermitState.SIGNED : UseOrderPermitState.NOT_SIGNED,
      signatureData: isSignatureDataValid ? signatureData : null,
      gatherPermitSignature: async function gatherPermitSignature() {
        //TODO: Why do we need permit validity buffer, the permit can only be used before the transaction deadline?
        const signatureDeadline = transactionDeadline.toNumber() + PERMIT_VALIDITY_BUFFER
        const domain = {
          name: 'AssetManagerBase',
          version: '1',
          chainId,
          verifyingContract: getAddress(ASSET_MANAGER_ADDRESS_MAP[chainId]), // use checksummed address
        }

        const message = {
          orderType: order.orderType.toString(),
          organizationId: accountIdentityKey.organizationId.toString(),
          relatedId: accountIdentityKey.relatedId.toString(),
          assetForSale: getAssetKey(order.assetForSale),
          assetForSaleAmount: getOrderAssetAmount(order.assetForSale).toString(),
          isAssetToReceiveUnknown: order.isAssetToReceiveUnknown || getAssetToReceiveIsUnknown(order.assetToReceive),
          assetToReceive: getAssetKey(order.assetToReceive),
          assetToReceiveAmount: getOrderAssetAmount(order.assetToReceive).toString(),
          expirationTimeStamp: order.expirationTimeStamp.toString(),
          params: hexlify(order.params), // try '0x' next
          deadline: signatureDeadline.toString(),
        }

        const data = JSON.stringify({
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' },
            ],
            Order: [
              { name: 'orderType', type: 'uint256' },
              { name: 'organizationId', type: 'uint256' },
              { name: 'relatedId', type: 'uint256' },
              { name: 'assetForSale', type: 'AssetKey' },
              { name: 'assetForSaleAmount', type: 'uint256' },
              { name: 'isAssetToReceiveUnknown', type: 'bool' },
              { name: 'assetToReceive', type: 'AssetKey' },
              { name: 'assetToReceiveAmount', type: 'uint256' },
              { name: 'expirationTimeStamp', type: 'uint256' },
              { name: 'params', type: 'bytes' },
              { name: 'deadline', type: 'uint256' },
            ],
            AssetKey: [
              { name: 'assetAddress', type: 'address' },
              { name: 'id', type: 'uint256' },
              { name: 'assetType', type: 'uint8' },
            ],
          },
          domain,
          primaryType: 'Order',
          message,
        })

        return provider
          .send('eth_signTypedData_v4', [getAddress(account), data])
          .catch((err) => {
            console.log('error building signature: ' + err.message)
          })
          .then(splitSignature)
          .then((signature) => {
            const signedSignatureData: OrderSignatureData = {
              v: signature.v,
              r: signature.r,
              s: signature.s,
              deadline: signatureDeadline,
              order: {
                id: 0,
                orderType: message.orderType,
                accountId: BYTES_EMPTY,
                organizationId: accountIdentityKey.organizationId,
                relatedId: accountIdentityKey.relatedId,
                assetForSale: {
                  assetAddress: message.assetForSale.assetAddress,
                  id: message.assetForSale.id,
                  assetType: message.assetForSale.assetType,
                },
                assetForSaleAmount: message.assetForSaleAmount,
                isAssetToReceiveUnknown: message.isAssetToReceiveUnknown,
                assetToReceive: {
                  assetAddress: message.assetToReceive.assetAddress,
                  id: message.assetToReceive.id,
                  assetType: message.assetToReceive.assetType,
                },
                assetToReceiveAmount: message.assetToReceiveAmount,
                expirationTimeStamp: message.expirationTimeStamp,
                feeDetails: {
                  assetKey: {
                    assetAddress: message.assetForSale.assetAddress,
                    id: 0,
                    assetType: 0,
                  },
                  amount: 0,
                  feeAccountId: BYTES_EMPTY,
                },
                params: message.params,
              },
              chainId,
            }

            setSignatureData(signedSignatureData)
            return signedSignatureData
          })
      },
    }
  }, [order, account, chainId, transactionDeadline, provider, signatureData, accountIdentityKey])
}
