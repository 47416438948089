import { useEffect, useRef } from 'react'

// eslint-disable-next-line import/no-unused-modules
export function useLogDependencyChanges(dependencies: any[], dependencyNames: string[]) {
  const previousDependencies = useRef(dependencies)

  useEffect(() => {
    dependencies.forEach((dep, index) => {
      if (previousDependencies.current[index] !== dep) {
        console.log(`${dependencyNames[index]} has changed:`, {
          previous: previousDependencies.current[index],
          current: dep,
        })
      }
    })
    previousDependencies.current = dependencies
  }, [dependencies, dependencyNames])
}
