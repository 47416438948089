// eslint-disable-next-line no-restricted-imports
import { Currency, CurrencyAmount, NativeCurrency, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useCachedPortfolioBalancesQuery } from 'components/AccountDrawer/PrefetchBalancesWrapper'
import { supportedChainIdFromGQLChain } from 'graphql/data/util'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import { TokenBalances } from 'lib/hooks/useTokenList/sorting'
import { useCallback, useMemo, useState } from 'react'

import { CurrencySearchBase } from './CurrencySearchBase'

// eslint-disable-next-line import/no-unused-modules
export interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  onlyShowCurrenciesWithBalance?: boolean
  showManageView?: () => void
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  onDismiss,
  isOpen,
  onlyShowCurrenciesWithBalance,
  showManageView,
}: CurrencySearchProps) {
  const { chainId, account } = useWeb3React()
  const [allCurrencies, setAllCurrenciesState] = useState<(NativeCurrency | Token)[]>([])

  const setAllCurrencies = useCallback((currencies: (NativeCurrency | Token)[]) => {
    setAllCurrenciesState(currencies)
  }, [])

  // Account balances fetches
  const accountBalances = useCurrencyBalances(account, allCurrencies)
  const { data, loading: queryBalancesAreLoading } = useCachedPortfolioBalancesQuery({ account })

  const graphQlBalances: TokenBalances = useMemo(() => {
    return (
      data?.portfolios?.[0].tokenBalances?.reduce((balanceMap, tokenBalance) => {
        if (
          tokenBalance.token?.chain &&
          supportedChainIdFromGQLChain(tokenBalance.token?.chain) === chainId &&
          tokenBalance.token?.address !== undefined &&
          tokenBalance.denominatedValue?.value !== undefined
        ) {
          const address = tokenBalance.token?.standard === 'ERC20' ? tokenBalance.token?.address?.toLowerCase() : 'ETH'
          const usdValue = tokenBalance.denominatedValue?.value
          const balance = tokenBalance.quantity
          balanceMap[address] = { usdValue, balance: balance ?? 0 }
        }
        return balanceMap
      }, {} as TokenBalances) ?? {}
    )
  }, [chainId, data?.portfolios])

  const rpcBalances: TokenBalances = useMemo(() => {
    const filteredBalances = (accountBalances.filter((x) => x) || []) as CurrencyAmount<Token>[]
    return (
      filteredBalances.reduce((balanceMap, tokenBalance) => {
        const address = !tokenBalance.currency?.isNative ? tokenBalance.currency.address.toLowerCase() : 'ETH'
        const usdValue = Number(tokenBalance.toFixed())
        const balance = Number(tokenBalance.toFixed())
        balanceMap[address] = { usdValue, balance }
        return balanceMap
      }, {} as TokenBalances) ?? {}
    )
  }, [accountBalances])

  const balances = useMemo(() => {
    return Object.keys(graphQlBalances).length ? graphQlBalances : rpcBalances
  }, [graphQlBalances, rpcBalances])

  const balancesAreLoading =
    queryBalancesAreLoading || accountBalances == undefined || Object.keys(balances).length === 0

  const handleCurrencySelect = useCallback(
    (currency: Currency, hasWarning?: boolean) => {
      onCurrencySelect(currency, hasWarning)
      if (!hasWarning) onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  return (
    <CurrencySearchBase
      isOpen={isOpen}
      onDismiss={onDismiss}
      selectedCurrency={selectedCurrency}
      otherSelectedCurrency={otherSelectedCurrency}
      showCommonBases={showCommonBases}
      showCurrencyAmount={showCurrencyAmount}
      disableNonToken={disableNonToken}
      onlyShowCurrenciesWithBalance={onlyShowCurrenciesWithBalance}
      showManageView={showManageView}
      getAccountBalance={() => ({ balances, isLoading: balancesAreLoading })}
      setAllCurrencies={setAllCurrencies}
      onCurrencySelect={handleCurrencySelect}
    />
  )
}
