import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { L2_CHAIN_IDS } from 'constants/chains'
import { DEBOUNCED_TRANSACTION_INTERVAL_SECONDS, L2_DEADLINE_FROM_NOW } from 'constants/misc'
import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'

import useCurrentBlockTimestamp from './useCurrentBlockTimestamp'

// combines the block timestamp with the user setting to give the deadline that should be used for any submitted transaction
export default function useTransactionDeadline(): BigNumber | undefined {
  const { chainId } = useWeb3React()
  const ttl = useAppSelector((state) => state.user.userDeadline)
  const blockTimestamp = useCurrentBlockTimestamp()
  return useMemo(() => {
    if (blockTimestamp && chainId && L2_CHAIN_IDS.includes(chainId)) return blockTimestamp.add(L2_DEADLINE_FROM_NOW)
    if (blockTimestamp && ttl) return blockTimestamp.add(ttl)
    return undefined
  }, [blockTimestamp, chainId, ttl])
}

function useDebouncedTransactionDeadlineRaw(
  deadline: BigNumber | undefined,
  debounceIntervalInSeconds?: number
): number | undefined {
  const debounceIntervalInSecondsOrDefault = debounceIntervalInSeconds ?? DEBOUNCED_TRANSACTION_INTERVAL_SECONDS
  return useMemo(() => {
    if (!deadline) return undefined

    // Convert deadline to seconds
    const deadlineInSeconds = deadline.toNumber()

    // Find the nearest interval based on the debounceIntervalInSeconds
    const roundedDeadline =
      Math.ceil(deadlineInSeconds / debounceIntervalInSecondsOrDefault) * debounceIntervalInSecondsOrDefault

    // Convert it back to BigNumber
    return roundedDeadline
  }, [deadline, debounceIntervalInSecondsOrDefault])
}

// eslint-disable-next-line import/no-unused-modules
export function useDebouncedTransactionDeadline(
  deadline: BigNumber | undefined,
  debounceIntervalInSeconds?: number
): BigNumber | undefined {
  const rawDeadline = useDebouncedTransactionDeadlineRaw(deadline, debounceIntervalInSeconds)

  return useMemo(() => {
    if (!rawDeadline) return undefined
    return BigNumber.from(rawDeadline)
  }, [rawDeadline])
}
